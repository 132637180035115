// 进入全屏
export function fullScreen () {
  let el = document.documentElement;
  let rfs = el.requestFullScreen || el.webkitRequestFullScreen || el.mozRequestFullScreen || el.msRequestFullscreen;
  if (typeof rfs !== 'undefined' && rfs) {
    rfs.call(el);
  }
  return;
}

// 退出全屏
export function exitScreen () {
  if (document.exitFullscreen) {
    document.exitFullscreen();
  } else if (document.mozCancelFullScreen) {
    document.mozCancelFullScreen();
  } else if (document.webkitCancelFullScreen) {
    document.webkitCancelFullScreen();
  } else if (document.msExitFullscreen) {
    document.msExitFullscreen();
  }
}

// 判断是否为全屏
export function isFullScreen () {
  return !!(
    document.fullscreenElement
        || document.mozFullScreen
        || document.webkitIsFullScreen
        || document.webkitFullScreen
        || document.msFullScreen
  );
}

