import Enum from '@/utils/Enum';

const BusinessTypeListEnum = [
  { type: 2, label: '服务商' },
  { type: 3, label: '经销商' },
  { type: 5, label: '医疗机构' },
  { type: 1, label: '厂商' },
  { type: 4, label: '其他' },
];

export default new Enum(BusinessTypeListEnum);
export { BusinessTypeListEnum };
